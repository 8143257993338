<template>
  <fieldset :class="{ box: !isViewModeCreate }">
    <page-title
      :title="$t('page.reqDirector.title')"
      @edit="$emit('edit')"
    >
      <p class="has-text-justified">
        {{ $t('page.reqDirector.description') }}
      </p>
    </page-title>
    <div
      v-for="(director, index) in model"
      :key="index"
      class="box"
    >
      <button
        v-if="isViewModeCreate && model.length > 1"
        role="button"
        type="button"
        class="delete"
        tabindex="100"
        @click="removeDirector(index)"
      >
      </button>
      <h3>{{ $t('page.director.section') + ' ' + (index + 1) }}</h3>
      <div class="columns">
        <div class="column is-half">
          <b-field
            :label="getFieldLabel('firstname')"
            :type="getFieldType('firstname', index)"
            :message="getFieldMessage('firstname', index)"
          >
            <b-input
              v-if="isFieldEditable(`$.director[${index}].firstname`)"
              v-model="director.firstname"
              @blur="validateFirstname(index)"
              custom-class="page-focus-first"
              :name="`fname${index}`"
              autocomplete="given-name"
            >
            </b-input>
            <span v-else>{{ director.firstname }}</span>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field
            :label="getFieldLabel('lastname')"
            :type="getFieldType('lastname', index)"
            :message="getFieldMessage('lastname', index)"
          >
            <b-input
              v-if="isFieldEditable(`$.director[${index}].lastname`)"
              v-model="director.lastname"
              @blur="validateLastname(index)"
              :name="`lname${index}`"
              autocomplete="family-name"
            >
            </b-input>
            <span v-else>{{ director.lastname }}</span>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field
            :label="getFieldLabel('address')"
            :type="getFieldType('address', index)"
            :message="getFieldMessage('address', index)"
          >
            <b-input
              v-if="isFieldEditable(`$.director[${index}].address`)"
              v-model="director.address"
              @blur="validateAddress(index)"
              :name="`address${index}`"
              autocomplete="address-line1"
            >
            </b-input>
            <span v-else>{{ director.address }}</span>
          </b-field>
        </div>
      </div>
      <div
        v-if="isViewModeCreate || director.useProfessionalAddress"
        class="columns"
      >
        <div class="column">
          <b-checkbox
            v-if="isViewModeCreate"
            v-model="director.useProfessionalAddress"
            class="mb-3"
          >
            {{ $t('page.reqDirector.useProfessionalAddress') }}
          </b-checkbox>
          <b-field
            v-if="director.useProfessionalAddress"
            :label="getFieldLabel('professionalAddress')"
            :type="getFieldType('professionalAddress', index)"
            :message="getFieldMessage('professionalAddress', index)"
          >
            <b-input
              v-if="isFieldEditable(`$.director[${index}].professionalAddress`)"
              v-model="director.professionalAddress"
              @blur="validateProfessionalAddress(index)"
              :name="`professionalAddress${index}`"
              autocomplete="address-line1"
            >
            </b-input>
            <span v-else>{{ director.professionalAddress }}</span>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <b-field
            :label="getFieldLabel('birthdate')"
            :type="getFieldType('birthdate', index)"
            :message="getFieldMessage('birthdate', index)"
          >
            <b-datepicker
              v-if="isFieldEditable(`$.director[${index}].birthdate`)"
              v-model="director.birthdate"
              :max-date="atLeastEighteenYearsOld"
              :date-formatter="formatDateWithSlashes"
              icon="calendar-day"
              trap-focus
              @blur="validateBirthdate(index)"
            >
            </b-datepicker>
            <span v-else>{{ formatBirthdate(director.birthdate) }}</span>
          </b-field>
        </div>
      </div>
      <div
        v-if="isViewModeCreate"
        class="columns"
      >
        <div class="column is-half">
          <b-field
            :label="getFieldLabel('ids')"
            :type="getFieldType('ids', index)"
            :message="getFieldMessage('ids', index)"
          >
            <b-upload
              v-model="localFiles[index]"
              @input="updateFiles(index)"
              multiple
              drag-drop
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon
                      icon="upload"
                      size="is-large"
                    >
                    </b-icon>
                  </p>
                  <p>{{ $t('form.field.ids.action') }}</p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div
            v-if="localFiles[index]"
            class="tags"
          >
            <span
              v-for="(file, fileIndex) in localFiles[index].filter(f => f.name)"
              :key="fileIndex"
              class="tag is-primary is-medium"
            >
              {{ file.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteDropFile(index, fileIndex)"
              >
              </button>
            </span>
          </div>
        </div>
      </div>
      <div v-else>
        <b-field
          :label="getFieldLabel('ids')"
          :type="getFieldType('ids', index)"
          :message="getFieldMessage('ids', index)"
          class="mb-1"
        >
        </b-field>
        <div class="content">
          <ul class="mt-0">
            <li
              v-for="(file, fileIndex) in localFiles[index]"
              :key="fileIndex"
            >
              {{ file.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <b-field class="has-text-centered">
      <b-button
        v-if="isViewModeCreate && model.length < 20"
        @click="addDirector"
        type="is-text is-anchor"
      >
        {{ $t('page.director.add') }}
      </b-button>
    </b-field>
  </fieldset>
</template>

<script>
import api from '@/api';
import page from '@/mixins/page';
import date from '@/mixins/date';
import { mapActions, mapState } from 'vuex';
import cloneDeep from 'lodash-es/cloneDeep';

const MAX_SIZE = 20 * 1024 * 1024;

export default {
  name: 'PageReqDirector',
  mixins: [
    page('reqDirector'),
    date
  ],
  data () {
    return {
      localFiles: []
    };
  },
  mounted () {
    if (!this.directorFiles.length) {
      this.model.forEach(() => this.localFiles.push([]));
    } else {
      this.localFiles = cloneDeep(this.directorFiles);
    }

    if (this.model.length === 0) {
      this.prefillDirectors();
    }
  },
  computed: {
    ...mapState('form', {
      directorFiles: 'directorFiles'
    })
  },
  methods: {
    ...mapActions('form', [
      'setDirectorFiles'
    ]),
    deleteDropFile (index, fileIndex) {
      if (!(this.localFiles[index][fileIndex] instanceof File) && this.localFiles[index][fileIndex].id) {
        api.deleteReqAttachment(this.localFiles[index][fileIndex].id);
      }
      this.localFiles[index].splice(fileIndex, 1);
      this.updateFiles(index);
    },
    updateFiles (index) {
      if (index !== undefined)
        this.validateIds(index);

      this.setDirectorFiles(this.localFiles);
    },
    addDirector () {
      this.model.push({
        address: '',
        birthdate: undefined,
        firstname: '',
        lastname: '',
        professionalAddress: '',
        useProfessionalAddress: false
      });
      this.localFiles.push([]);
    },
    prefillDirectors () {
      if (this.storedModel.neq.administrateur) {
        this.storedModel.neq.administrateur.forEach(director => {
          this.model.push({
            address: director.adresse,
            birthdate: undefined,
            firstname: director.prenom,
            lastname: director.nom,
            professionalAddress: '',
            useProfessionalAddress: false
          });
          this.localFiles.push([]);
        });
      }
    },
    removeDirector (index) {
      this.model.splice(index, 1);
      this.localFiles.splice(index, 1);
      this.clearErrors();
      this.updateFiles();
    },
    validateAddress (index) {
      this.validateNotEmpty(`address${index}`, this.model[index].lastname);
    },
    validateIds (index) {
      let totalSize = 0;
      let extensionValid = true;
      this.localFiles[index]?.forEach((file) => {
        totalSize += file.size;
        let extension = file.name?.split('.')?.pop();
        if (!['png', 'pdf', 'jpeg', 'jpg'].includes(extension?.toLowerCase())) {
          extensionValid = false;
        }
      });

      if (this.localFiles[index]?.length < 1 || totalSize > MAX_SIZE || !extensionValid)
        this.addError(`ids${index}`);
      else
        this.removeError(`ids${index}`);
    },
    validateBirthdate (index) {
      if (!this.model[index].birthdate)
        this.addError(`birthdate${index}`);
      else
        this.removeError(`birthdate${index}`);
    },
    validateFirstname (index) {
      this.validateNotEmpty(`firstname${index}`, this.model[index].firstname);
    },
    validateLastname (index) {
      this.validateNotEmpty(`lastname${index}`, this.model[index].lastname);
    },
    validateProfessionalAddress (index) {
      if (this.model[index].useProfessionalAddress && this.model[index].professionalAddress.length < 1)
        this.addError(`professionalAddress${index}`);
      else
        this.removeError(`professionalAddress${index}`);
    },
    validate () {
      this.model.forEach((director, index) => {
        this.validateAddress(index);
        this.validateBirthdate(index);
        this.validateFirstname(index);
        this.validateIds(index);
        this.validateLastname(index);
        this.validateProfessionalAddress(index);
      });
    }
  }
};
</script>
